import React from 'react';
import ReactDOM from 'react-dom/client';





const myApostrophe = "'";



const bibleArr = [
  {
    verse: '"I consider that our present sufferings are not worth comparing with the glory that will be revealed in us. ',
    
    reference: "Romans 8:18"
  },

  {
    verse: '"And we know that in all things God works for the good of those who love him, who have been called according to his purpose. ',
    
    reference: "Romans 8:28"
  },

  {
    verse: '"But God demonstrates his own love for us in this: While we were still sinners, Christ died for us.',
    
    reference: "Romans 5:8"
  },

  {
    verse: '"for all have sinned and fall short of the glory of God, and all are justified freely by his grace through the redemption that came by Christ Jesus.',
    
    reference: "Romans 3:23-24"
  },

  {
    verse: '"23 For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.',
    
    reference: "Romans 6:23"
  },

  {
    verse: '"If you declare with your mouth, “Jesus is Lord,” and believe in your heart that God raised him from the dead, you will be saved. ',
    
    reference: "Romans 10:9"
  },

  {
    verse: '"For since the creation of the world God' + myApostrophe + 's invisible qualities—his eternal power and divine nature—have been clearly seen, being understood from what has been made, so that people are without excuse.',
    
    reference: "Romans 1:20"
  },

  {
    verse: '"They have become filled with every kind of wickedness, evil, greed and depravity. They are full of envy, murder, strife, deceit and malice. They are gossips, slanderers, God-haters, insolent, arrogant and boastful; they invent ways of doing evil; they disobey their parents; they have no understanding, no fidelity, no love, no mercy. ',
    
    reference: "Romans 1:29-31"
  },

  {
    verse: '"Therefore, there is now no condemnation for those who are in Christ Jesus, because through Christ Jesus the law of the Spirit who gives life has set you free from the law of sin and death.',
    
    reference: "Romans 8:1-2"
  },

  {
    verse: '"What, then, shall we say in response to these things? If God is for us, who can be against us? He who did not spare his own Son, but gave him up for us all—how will he not also, along with him, graciously give us all things?',
    
    reference: "Romans 8:31-32"
  }


]

class Verse extends React.Component {
  

  

  render () {
    
    const generalStyle = this.props.colors
    console.log(this.props.colors + "colors in Verse element")

    return (
      <div id="main-verse-container" style={{background: generalStyle, height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <div id="quote-box" style={{background: "#ffffff", width: "80%", display: "flex", flexDirection: 'column', padding: '30px', height: 'auto'}}>
            <div id="text" style={{color: generalStyle}}>
              <h1>{bibleArr[this.props.verseNumber].verse}</h1>
            </div>
            <div id="author" style={{color: generalStyle, textAlign: 'right'}}>
              <p>{bibleArr[this.props.verseNumber].reference}</p>
            </div>
            <div>
              <p style={{color: '#ffffff'}}>by The Bible</p>  
      
            </div>  
          </div>

          
        </div>
    )
  }
}

class Myfunc extends React.Component {
  constructor (props) {
    super (props) 
      this.state = {
        randomIndex: Math.floor(Math.random() * 10),
        randomColorR: Math.floor(Math.random()*235),
        randomColorG: Math.floor(Math.random()*235),
        randomColorB: Math.floor(Math.random()*235) 
      }
      this.HandleClick = this.HandleClick.bind(this)
  }

  HandleClick() {
    this.setState ({
      randomIndex: Math.floor(Math.random() * 10),
      randomColorR: Math.floor(Math.random()*235),
      randomColorG: Math.floor(Math.random()*235),
      randomColorB: Math.floor(Math.random()*235)
    })
  }
  
  render () {
    console.log("red" + this.state.randomColorR + "green" + this.state.randomColorG + "blue" + this.state.randomColorB)
    const generalStyle = 'rgb(' + this.state.randomColorR + ',' + this.state.randomColorG + ',' + this.state.randomColorB + ')';
    
    console.log(generalStyle + "colors in Myfunc")


    const buttonStyle = {
      "borderRadius": "5px",
      "background": "#ffffff",
      "width": "30vw",
      "margin": "30px",
      "fontSize": "14px",
      "fontFamily": "Ubuntu",
      "textDecoration": "none",
      "color": "#000000",
    }

    const greetingStyle = {
      "background": "#ffffff",
      "borderTop": "1px solid #000000",
      "borderBottom": "1px solid #000000",
      "padding": "10px",
      "borderRadius": "10px"
    }

    const footerStyle = {
      "background": "#ffffff",
      "borderTop": "1px solid #000000",
      "borderBottom": "1px solid #000000",
      "padding": "10px"
      
    }

    return (
      
      <div id="main-container" style={{background: generalStyle, display: "flex", flexDirection: "column"}}>
        <div id="greeting" style={greetingStyle}>
          <h1>Welcome to benjoiner.pw!  Here are the links to all the stuff!</h1>
        </div>
        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/start" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Apologetics Start Page</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/game" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Youth Group Game</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/k" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Kalaam Cosomological Argument Quiz</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/us" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Inches Calculator</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/sample-quiz" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Sample Bible Study Quiz</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/yec" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Refutation of Young Earth Creationism</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/baldwin-skatepark-report.pdf" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Skatepark Report and Info</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/WBA-Camp-Schedule-2024.pdf" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Forge Camp Schedule 2024</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/combination-saw" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Table Miter Saw Patented</p></a>
        </div>
    
        <div style={buttonStyle}>
          <a href="https://benjoiner.pw/compound-interest-converter.html" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Compound Interest Converter</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://www.youtube.com/@benwantstosk8" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Skateboarding YouTube</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://www.youtube.com/@ApologeticsBenJoiner" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Christian Teaching YouTube</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://www.facebook.com/ben.joiner.9" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Ben Joiner's Facebook</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="twitter.com/BenJoiner4" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Ben Joiner's X</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://www.instagram.com/milledgevilleskates99/" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Milledgeville Skatepark Instagram</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://www.facebook.com/HelpMilledgeville" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Milledgeville Skatepark Facebook</p></a>
        </div>

        <div style={buttonStyle}>
          <a href="https://drive.google.com/file/d/0B6_ku8YV927jX0w4RVlNQWFSdk0/view?resourcekey=0-QN1M4xBOo18PTa9EylxEWg" target="blank"><p style={{fontSize: '14px', margin: '5px'}}>Central City Park Master Plan</p></a>
        </div>

    
    

        
        <Verse verseNumber={this.state.randomIndex} colors={generalStyle}/>
        

        <div id="actions-container" style={{display: 'flex', flexDirection: 'row'}}>
              <div id="sharing" style={{display: 'flex', flexDirection: 'row', width: "50%"}}>
                <a id="tweet-quote" href="https://twitter.com/intent/tweet?text=random-verse&url=https://benjoiner.pw/random-verseL" target="blank"><img src="https://i.imgur.com/aOUciGA.png" alt="twitter logo" style={{background: generalStyle, width: '30px', borderRadius: '3px', margin: '5px'}}></img></a>
                <a id="facebook-post-quote" href="https://www.facebook.com/sharer/sharer.php?u=https://benjoiner.pw/random-verse" target="blank"><img src="https://i.imgur.com/ED17R9U.png" alt="twitter logo" style={{background: generalStyle, width: '30px', borderRadius: '3px', margin: '5px'}}></img></a>
              </div>

              <div id="new-quote" style={{width: "50%", display: 'flex', justifyContent: 'right'}}>
                <button onClick={this.HandleClick} style={{background: generalStyle, color: "#ffffff", justifyContent: "right", fontSize: '15px', borderRadius: '5px', border: "3px solid #000", marginRight: '20px', marginBottom: '10px'}}>New quote</button>
              </div>
            </div>


        <div id="footer" style={footerStyle}>
                
         <p>contact Ben Joiner
          <a href="tel:4784563046">478-456-3046</a></p>
          <a href="mailto:benjoiner3@gmail.com">send email</a>
        </div>
      </div>
    )
  }
 
}





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Myfunc />
  </React.StrictMode>
);


